import React from 'react';
import { About } from '../../main/MainTabs/OverviewTab/About';
import { Addresses } from '../../main/MainTabs/OverviewTab/Addresses';
import { Faq } from '../../main/MainTabs/OverviewTab/Faq';
import { InstantMessaging } from '../../main/MainTabs/OverviewTab/InstantMessaging';
import { Totals } from './Totals';

export function OverviewTab() {
  return (
    <>
      <Totals />
      <About />
      <InstantMessaging />
      <Addresses />
      <Faq />
    </>
  );
}
