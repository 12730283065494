import React, { ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as MinusIcon } from '../../../../assets/img/minus.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/img/plus.svg';
import { LargeHeading } from '../../common';
import { responsive } from '../../theme';

const Wrapper = styled.div(({ theme }) => ({
  padding: '3rem 0',
  borderTop: `1px solid ${theme.border.secondary}`,
  display: 'flex',
  flexDirection: 'column',
  '& > h2': {
    marginRight: 'auto',
  },
  [responsive.md]: {
    alignItems: 'center',
    padding: '3rem 0 1.5rem 0',
  },
}));

const QuestionHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  alignItems: 'center',
});

const IconWrapper = styled.div(({ theme }) => ({
  paddingLeft: '10px',
  svg: {
    path: {
      stroke: theme.border.primary,
    },
  },
}));

const QuestionText = styled.div({
  fontSize: '20px',
  fontWeight: 500,
  padding: '25px 0',
  [responsive.sm]: {
    fontSize: '18px',
  },
});

const QuestionWrapper = styled.div(({ theme }) => ({
  borderTop: `1px solid ${theme.border.secondary}`,
  '&:first-child': {
    borderTop: 'none',
  },
}));

const AnswerText = styled.div({
  fontSize: '20px',
  fontWeight: 300,
  lineHeight: '129%',
  paddingBottom: '25px',
  [responsive.sm]: {
    fontSize: '18px',
  },
});

const Questions = styled.div({});

const BoldLink = styled.a(({ theme }) => ({
  fontWeight: 400,
  color: theme.text.primary,
}));

const Ol = styled.ol({
  paddingLeft: '1em',
});

interface FaqLinkProps {
  url: string;
  children: ReactNode;
}

function FaqLink({ url, children }: FaqLinkProps) {
  return (
    <BoldLink href={url} target="_blank" rel="noreferrer noopener">
      {children}
    </BoldLink>
  );
}

interface QuestionInfo {
  question: string;
  answer: ReactNode;
}

const newFaq: QuestionInfo[] = [
  {
    question: 'What is the flow regarding staking, KYC, and claiming rewards?',
    answer: (
      <Ol type="1">
        <li>
          Stake tokens to Nominator contract using the{' '}
          <FaqLink url="https://staking.synternet.com/">Staking Dashboard</FaqLink>. It is a two-step procedure
          consisting of approval and deposit. Don’t transfer your NOIA tokens directly to the Nominator smart contract.
        </li>
        <li>Rewards are calculated and displayed as total rewards every 30 minutes.</li>
        <li>Additional rewards become claimable every 12 hours.</li>
        <li>When you want to claim rewards above 10,000 USD, you must pass the KYC procedure.</li>
      </Ol>
    ),
  },
  {
    question: 'What are the Nominator stake size limits?',
    answer: <>The minimum stake is 100 NOIA tokens; the maximum stake is 5,000,000 NOIA tokens.</>,
  },
  {
    question: 'How long is the stake bonding period for a Nominator?',
    answer: <>Seven days.</>,
  },
  {
    question: 'What wallet can I use with Metamask?',
    answer: (
      <>
        You don’t have to create a Metamask wallet. If you are using a hardware wallet, such as Ledger or Trezor,
        connect your hardware wallet to Metamask, using “connect hardware wallet” from the menu. If you still have
        difficulties, seek community help in our{' '}
        <FaqLink url="https://discord.com/channels/503896258881126401/847141095690862602">Discord</FaqLink> or{' '}
        <FaqLink url="https://t.me/Synternet">Telegram</FaqLink> groups.
      </>
    ),
  },
  {
    question: 'Is there a withdrawal limit for rewards?',
    answer: (
      <>
        No, there’s no limit. However, after your total accumulated rewards on Nominator reach 10,000 USD in value, you
        will be asked to pass the KYC procedure until you can claim them.
      </>
    ),
  },
  {
    question: 'When will I be asked to pass KYC? How does KYC work?',
    answer: (
      <>
        When your rewards are less than 10,000 USD in value, you can claim them freely. After your total accumulated
        rewards as a Nominator reach 10,000 USD in value, you will be asked to pass the KYC procedure to claim them.
        During KYC, you will be redirected to a third-party website, where you will be asked to take a photo of your ID
        document as well as a selfie.
      </>
    ),
  },
  {
    question: 'Are my tokens at risk due to slashing?',
    answer: <>Nominators do not face slashing penalties.</>,
  },
  {
    question: 'If I stake as a Nominator, do I need to choose a Validator to nominate to?',
    answer: <>Nominators do not need to nominate validators.</>,
  },
  {
    question: 'Can anyone with a more significant stake than me push me out of staking?',
    answer: <>There are no slots for Nominators. Therefore no one can push you out.</>,
  },
  {
    question: 'Disclaimer, Terms, and Conditions',
    answer: (
      <>
        Some countries will be excluded from receiving token rewards from Synternet. Depending on your stake size, we
        might ask you to submit specific documentation to comply with KYC/AML regulations. Please familiarize yourself
        with Synternet Staking Terms and Conditions by visiting{' '}
        <FaqLink url="https://www.synternet.com/staking-disclaimer">
          https://www.synternet.com/staking-disclaimer
        </FaqLink>
        .
      </>
    ),
  },
];

export function Faq() {
  return (
    <Wrapper>
      <LargeHeading>F.A.Q.</LargeHeading>
      <Questions>
        {newFaq.map((info) => (
          <Question key={info.question} info={info} />
        ))}
      </Questions>
    </Wrapper>
  );
}

interface QuestionProps {
  info: QuestionInfo;
}

function Question({ info }: QuestionProps) {
  const { question, answer } = info;
  const [expanded, setExpanded] = useState(false);

  const handleClick = useCallback(() => {
    setExpanded((state) => !state);
  }, []);

  return (
    <QuestionWrapper>
      <QuestionHeader onClick={handleClick}>
        <QuestionText>{question}</QuestionText>
        <IconWrapper>{expanded ? <MinusIcon /> : <PlusIcon />}</IconWrapper>
      </QuestionHeader>
      {expanded && <AnswerText>{answer}</AnswerText>}
    </QuestionWrapper>
  );
}
