import React from 'react';
import { useSelector } from 'react-redux';
import { getTotalStaked } from '../../../utils/calculations';
import { getNominatorVars } from '../../../utils/env';
import { formatShortAddress } from '../../../utils/format';
import { NumberText, ValueHeading } from '../../main/common';
import { CopyAddress } from '../../main/CopyAddress';
import {
  AddressContent,
  AddressLink,
  StakeBox,
  StakeHeading,
  StakeInfo,
  Tokens,
} from '../../main/MainTabs/OverviewTab/Totals';
import { nominatorState } from '../slice';

export function Totals() {
  const { globalInfo } = useSelector(nominatorState);
  const nominatorAddress = getNominatorVars().address;

  return (
    <StakeInfo>
      <StakeBox>
        <StakeHeading>Number of nominators</StakeHeading>
        <Tokens>
          <NumberText>{globalInfo?.nominators_current}</NumberText>
        </Tokens>
      </StakeBox>
      <StakeBox>
        <StakeHeading>Total staked</StakeHeading>
        <Tokens>
          <NumberText>
            {getTotalStaked(globalInfo?.stake_rate_current)}
            <span>M</span>
          </NumberText>
          <ValueHeading>NOIA</ValueHeading>
        </Tokens>
      </StakeBox>
      <StakeBox>
        <StakeHeading>Contract address</StakeHeading>
        <AddressContent>
          <AddressLink
            href={`https://etherscan.io/address/${nominatorAddress}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {formatShortAddress(nominatorAddress, 6, 3)}
          </AddressLink>
          <CopyAddress text={nominatorAddress} />
        </AddressContent>
      </StakeBox>
    </StakeInfo>
  );
}
